html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.hot {
    color: red;
}

.little-cold {
    color: rgb(0, 183, 255);
}

.very-cold {
    color: blue;
}

.almost-comfortable {
    color: rgb(19, 203, 19);
}

.comfortable {
    color: rgb(21, 255, 21);
    font-weight: bold;
}

.sunny {
    color: yellow;
    background-color: #b6b6ff;
}

.cloudy {
    color: gray;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
:root {
    --header-height: 3rem;
    --hue: 174;
    --sat: 63%;
    --first-color: hsl(var(--hue), var(--sat), 40%);
    --title-color: hsl(var(--hue), 8%, 35%);
    --text-color: hsl(var(--hue), 8%, 35%);
    --body-color: hsl(var(--hue), 100%, 99%);
    --container-color: #222;
    --body-font: 'Open Sans', sans-serif;
    --normal-font-size: .938rem;
    --tiny-font-size: .7rem;
}

nav {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

body {
    padding: 0;
    margin: 0;
}

nav {
    /* margin: var(--header-height) 0 0 0; */
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
}

nav ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

nav a {
    text-decoration: none;
}

div#nav-menu {
    padding: 0px;
    margin: 0px;
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__img {
    width: 32px;
    border-radius: 50%;
}

.nav__logo {
    color: var(--title-color);
    font-weight: 600;
}

.nav__list,
.nav__link {
    display: flex;
}

.nav__link {
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    color: hsl(174deg 7% 60%);
    font-weight: 550;
}

.nav__list {
    justify-content: space-around;
}

.nav__name {
    font-size: var(--tiny-font-size);
}

.nav__icon {
    font-size: 1.5rem;
}

.active-link, .nav__link:hover, .nav__link:active {
    position: relative;
    color: hsl(174deg 100% 61%);
    transition: .3s;
}


/* For small devices */

@media screen and (max-width: 320px) {
    .nav__name {
        display: none;
    }
}


/* For medium devices */

@media screen and (min-width: 576px) {
    .nav__list {
        justify-content: center;
        column-gap: 3rem;
    }
}

.nav__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--container-color);
    box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15);
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 1rem 1rem 0 0;
    transition: .4s;
}

.section {
    /* padding: 4.5rem 0 2rem; */
    word-wrap: break-word;
}

.section-height {
    height: calc(100vh - 4rem);
}


/*=============== LAYOUT ===============*/

.container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

a, a:visited {
    color: #96b4d1;
}

a:hover {
    color: #4da5fc;
}

.map-container {
    height: calc(100vh - 4rem - 183px) !important;
    width: 100%;
}

.webcam-container-list > img {
    width: 100%;
  }