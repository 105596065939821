// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.dark.less"; // ng-zorro-antd.dark.less

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
// .ant-slider-mark-text {
//     color: #000000B0;
// }

.ant-slider-with-marks {
    margin-bottom: 31px;
}

body {
    overflow: hidden;
}

// .ant-switch-checked {
//     background-color: #b0d0ef;
// }

.ant-slider-mark-text {
    color: rgba(255, 255, 255, 0.65);
}

.ant-slider-handle {
    border: solid 2px #ffffff;
    background-color: #2f76b4;
}

.ant-slider-dot-active {
    border-color: #2f76b4;
}

.ant-slider-track {
    background-color: #255d8d;
}

nav, #nav-menu {
    z-index: 99999;
}

.ant-tabs-content-holder {
    overflow: auto;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background: #171929;
}

.ant-btn:focus {
    color: inherit;
    border-color: inherit;
}